<template>
    <li class="list__item item item_view_head"
        :class="{'item_type_collapses-group': true, 'item_status_collapsed': false, 'item_active': showActive == true}"
        @click.stop="showActive = !showActive"
    >
        <h4 class="list__title"
            @click.stop="setGroupShowUnits({groupId:groupId, showUnits: !showUnits})"
        >
            {{groupName}} ({{groupUnits}})
        </h4> <!-- {{unitsGroupFilteredUnits(group.id).length}} -->
        <UnitsList_group_status :group-id="groupId" />
        <UnitsList_group_menu v-if="true" :group-id="groupId" />
    </li>
</template>
<script>
    import {
        mapActions,
        // mapMutations,
        mapGetters,
    } from "vuex";
    import UnitsList_group_menu from "./UnitsList_group_menu.vue";
    import UnitsList_group_status from "./UnitsList_group_status.vue";

    export default {
        name: 'UnitsList_group',
        components: {UnitsList_group_status, UnitsList_group_menu},
        props: [
            'groupId',
            // 'group',
        ],
        data() {
            return {
                showActive: false,
            }
        },
        computed: {
            ...mapGetters([
                "unitsGroups",
                "unitsGroupsByIds",
                "groupsShowUnits",
                "unitsGroupsFiltered",
            ]),
            group(){
                return this.unitsGroupsFiltered.find(g => {
                    return g.id === this.groupId
                })
                // return this.unitsGroupsByIds[this.groupId]
            },
            groupName(){
                return this.group.name
            },
            groupUnits(){
                return this.group.units.length
            },
            showUnits(){
                return this.groupsShowUnits.includes(this.groupId)
            }
        },
        methods: {
            ...mapActions([
                "setGroupShowUnits",
            ]),
        },
        updated() {
            //console.log(`group ${this.groupId} updated`);
        }
    }
</script>