<template>
    <section class="section__body">

        <!--- Navigation --->
        <UnitsList_navigation />

        <!--- Container --->
        <section class="section__container container container_active separator-when-scrolling plr__12 pb__12">
            <RecycleScroller
                    :items="unitsGroupsFilteredWithSize"
                    :min-item-size="56"
                    :buffer="1000"
                    page-mode
            >
                <template #before>
                    <ul class="list mb__12">
                        <li class="list__item item item_view_head">
                            <h4 class="list__title">{{ $t('text.All units') }} ({{unitsCount}})</h4>
                        </li>
                    </ul>
                </template>

                <template v-slot="{ item, index, active }">
<!--                    <DynamicScrollerItem-->
<!--                            :item="item"-->
<!--                            :active="active"-->
<!--                            :data-index="index"-->
<!--                            :data-active="active"-->
<!--                            :size-dependencies="[groupsShowUnits,filteredUnits,item.units,]"-->
<!--                            tag="ul"-->
<!--                            class="list mb__12"-->
<!--                            :class="{'list_status_collapsed': !groupsShowUnits.includes(item.id)}"-->
<!--                    >-->
                    <ul class="list mb__12 list_status_collapsed-z"
                        :class="{'list_status_collapsed': !groupsShowUnits.includes(item.id)}"
                        :key="'UnitsList--'+item.id"
                        :active="active"
                        :data-index="index"
                        :data-active="active"
                    >
                        <UnitsList_group
                                :key="'UnitsList-group-'+item.id"
                                :group-id="item.id"
                        />

                        <UnitsList_unit
                                v-for="unitId in item.units"
                                :key="'UnitsList-group-'+item.id+'-unit-'+unitId"
                                :unit-id="unitId"
                                :group-id="item.id"
                                :filtered-unit="filteredUnits.includes(unitId)"
                                :show-unit="groupsShowUnits.includes(item.id)"
                        />
                    </ul>
<!--                    </DynamicScrollerItem>-->
                </template>

                <template #after>
                    <!--  end -->
                </template>
            </RecycleScroller>
        </section>
    </section>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import UnitsList_navigation from "./UnitsList_navigation.vue";
    import UnitsList_group from "./UnitsList_group.vue";
    import UnitsList_unit from "./UnitsList_unit.vue"

    export default {
        name: "UnitsList",
        components: {
            // VueAutoVirtualScrollList,
            UnitsList_navigation,
            UnitsList_group,
            UnitsList_unit,
        },
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                "unitsGroups",
                "unitsCount",
                "unitsByIds",
                "groupsFavorite",
                "groupsShowUnits",
                "filteredUnits",
                "unitsGroupsFiltered",
            ]),
            unitsGroupsFilteredWithSize(){
                // console.log('unitsGroupsFilteredWithSize', this.unitsGroups, this.filteredUnits)
                let collator = new Intl.Collator();
                let groups = this.unitsGroupsFiltered
                let favorites = this.groupsFavorite
                groups.sort(function (a, b) {
                    let cmpF = (favorites.includes(a.id) ? 0 : 1)
                             - (favorites.includes(b.id) ? 0 : 1)
                    if(cmpF) {
                        return cmpF
                    }
                    let cmp = collator.compare(a.name, b.name)
                    if (cmp) {
                        return cmp;
                    }
                    return a.id - b.id;
                })

                return groups.reduce((out, g) => {
                    let isShowUnits = this.groupsShowUnits.includes(g.id)
                    let size = isShowUnits ? 52*g.units.length : 0
                    out.push({
                        ...g,
                        size: 44+12+size
                    })
                    return out
                }, [])
            },
/*
            unitsGroupsWithSize(){
                // console.log('unitsGroupsWithSize', this.unitsGroups, this.filteredUnits)
                return this.unitsGroups.reduce((out, g) => {
                    let isShowUnits = this.groupsShowUnits.includes(g.id)
                    let size = g.units.length*52*isShowUnits
                    // let size = 0
                    // if (isShowUnits) {
                    //     let units = g.units.filter(u => {
                    //         return this.filteredUnits.includes(u.id)
                    //     })
                    //     size = 52*units.length
                    // }
                    out.push({
                        id: g.id,
                        units: g.units,
                        size: 44+12+size
                    })
                    return out
                }, [])
            }
 */
        },
        methods: {
            ...mapActions([
                "setSectionInfo",
            ]),
        },
        updated() {
            // console.log('UnitsList updated'
            //     // , this.unitsGroupsFilteredWithSize.filter(g => { return g.size>44+12 }).map(g => g.id)
            //     // , this.unitsGroupsWithSize.filter(g => { return g.size>44+12 }).map(g => g.id)
            // )
        }
    }
</script>

<style scoped>

</style>