<template>
    <div class="list__status">
        <span v-show="isFavorite" class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__status_favorite"/></span> </span>
    </div>
</template>
<script>
    import {
        // mapActions,
        mapGetters,
    } from "vuex";

    export default {
        name: 'UnitsList_group_status',
        components: {},
        props: ['groupId'],
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                "groupsFavorite",
                "unitsGroupsByIds",
            ]),
            isFavorite(){
                return this.groupsFavorite.includes(this.groupId)
            },
        },
        methods: {
            // ...mapActions([
            // ]),
        }

    }
</script>