<template>
    <div class="list__button-group">
        <button class="button button_view_icon button_theme_base" data-type="open-drop-menu">
            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__more"/></span> </span>
        </button>
        <div class="list__dropdown-menu dropdown-menu position_center-bottom" data-type="">
            <div class="dropdown-menu__wrap">
                <ul class="dropdown-menu__list">
                    <li class="dropdown-menu__item item" @click="setGroupFavorite({groupId:groupId, isFavorite: !isFavorite })">
                        <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__favorite"/></span> </span>
                        <span class="dropdown-menu__item-name">{{ $t('menu.Favorite group') }}</span>
                    </li>
                    <li class="dropdown-menu__item item" @click="displaySectionWidget('GroupCommand')">
                        <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__command"/></span> </span>
                        <span class="dropdown-menu__item-name">{{ $t('menu.Command') }}</span>
                    </li>

                    <li class="dropdown-menu__item item item_type_separator"></li>
                    <li class="dropdown-menu__item item is_danger" @click="deleteGroup()">
                        <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__delete_lg"/></span> </span>
                        <span class="dropdown-menu__item-name">{{ $t('menu.Delete') }}</span>
                    </li>
                    <li class="dropdown-menu__item item" @click="displaySectionWidget('GroupEdit')">
                        <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__edit_lg"/></span> </span>
                        <span class="dropdown-menu__item-name">{{ $t('menu.Edit') }}</span>
                    </li>
                </ul>
            </div>
        </div>
        <button class="button button_view_icon button_theme_base" @click="zoomGroup()">
            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__zoom"/></span> </span>
        </button>
        <UnitsList_group_menu_onmap :group-id="groupId" />
    </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
    import UnitsList_group_menu_onmap from "./UnitsList_group_menu_onmap.vue";

    export default {
        name: 'UnitsList_group_menu',
        components: {UnitsList_group_menu_onmap},
        props: ['groupId'],
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                "groupsFavorite",
                "unitsGroupsByIds",
                "unitsGroupsFiltered",
                "unitsLmsgsByIds",
            ]),
            isFavorite(){
                return this.groupsFavorite.includes(this.groupId)
            },
        },
        methods: {
            ...mapActions([
                "setGroupFavorite",
                "setGroupShowUnits",
                "setUnitsShowOnMap",
                "setMapBoundsByUnits",
                "setSectionInfo",
                "setSectionWidget",
            ]),
            ...mapMutations([
                //"setAllUnitsShowOnMap",
            ]),
            displaySectionInfo(component){
                this.setSectionInfo({
                    component: component,
                    props: {
                        groupId: this.groupId
                    }
                })
            },
            displaySectionWidget(component){
                this.setSectionWidget({
                    component: component,
                    props: {
                        groupId: this.groupId
                    }
                })
            },
            zoomGroup(){
                let group = this.unitsGroupsFiltered.find(g => {
                    return g.id === this.groupId
                })
                if(!group || !group.units.length){
                    // this.setMapBounds(null)
                    return false
                }
                this.setMapBoundsByUnits(group.units)
                // this.setAllUnitsShowOnMap(group.units)
                this.setUnitsShowOnMap({ showOnMap: true, unitIds: group.units})
            },
            deleteGroup(){
            },
        }
    }
</script>