<template>
    <ButtonIcon_dual_status
        :disabled="true"
        :icon="isAllUnitsOnMap ? 'common__show' : 'common__show_disabled'"
        :icons="['common__show', 'common__show_disabled']"
        :value="isAllUnitsOnMap"
        @click="setGroupShowUnitsOnMap"
    />
</template>
<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: 'UnitsList_group_menu_onmap',
        components: {},
        props: ['groupId'],
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                "unitsOnMap",
                "unitsGroupsByIds",
                "unitsGroupsFiltered",
            ]),
            isAllUnitsOnMap(){
                let group = this.unitsGroupsFiltered.find(g => {
                    return g.id === this.groupId
                })
                let ids = group.units.filter(id => {
                    return !this.unitsOnMap.includes(id)
                })
                return ids.length === 0
            },
        },
        methods: {
            ...mapActions([
                "setGroupFavorite",
                "setGroupShowUnits",
                "setUnitsShowOnMap",
            ]),
            setGroupShowUnitsOnMap(){
                let group = this.unitsGroupsFiltered.find(g => {
                    return g.id === this.groupId
                })
                this.setUnitsShowOnMap({
                    unitIds: group.units,
                    showOnMap: !this.isAllUnitsOnMap,
                })
            },
        }
    }
</script>